import React, { useState, useContext } from 'react';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { navigate } from 'gatsby';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { notNull, isNull } from 'utils/text';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { loginService, setLoginUser } from '../../services/loginService';
import { SettingsEthernetSharp } from '@material-ui/icons';

const awsConfig = {
  Auth: {
    region: process.env.AWS_CONFIG_PORTAL_REGION,
    userPoolId: process.env.AWS_CONFIG_PORTAL_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_CONFIG_PORTAL_USER_POOL_WEBCLIENT_ID,
    identityPoolId: process.env.AWS_CONFIG_PORTAL_IDENTITY_POOL_ID,
  },
};

Amplify.configure(awsConfig);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Pergo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/featured/?car,taxi)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function PasswordPage() {
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [formValues, setFormValues] = useState({
    email: '',
    code: '',
    password: '',
    cpassword: '',
  });
  const [formErrors, setFormErrors] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  const handleChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value
    });
  }

  const checkValueValidate = (id, value) => {
    let valid = notNull(value) && value.length > 0;
    let error = null;

    if (!valid) {
      error = 'Please input value';
    } else {
      if (id === 'email') {
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        error = valid ? null : 'Please input valid Email';
      } else if (id === 'password') {
        valid = value.length >= 6;
        error = valid ? null : 'Please input strong password';
      } else if (id === 'cpassword') {
        valid = formValues.password === formValues.cpassword;
        error = valid ? null : 'Please input correctly';
      }
    }

    return error;
  };

  const checkStepValidate = (keys) => {
    let errors = {};
    keys.forEach(id => {
      const error = checkValueValidate(id, notNull(formValues) ? formValues[id] : null);
      errors[id] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleSend = async () => {
    const keys = ['email'];
    if (!checkStepValidate(keys)) {
      return;
    }

    showCircularProgress('Please wait');
    try {
      const result = await Auth.forgotPassword(formValues.email);
      setStatus(1);
    } catch (error) {
      console.log(error);
      setFormErrors({
        email: "There's problem to send verify code"
      });
    }
    hideCircularProgress();
  }

  const handleReset = async () => {
    const keys = ['code', 'password', 'cpassword'];
    if (!checkStepValidate(keys)) {
      return;
    }
    
    showCircularProgress('Please wait');
    try {
      const result = await Auth.forgotPasswordSubmit(formValues.email, formValues.code, formValues.password);
      loginService.update_password(formValues.email, formValues.password).then(({ data }) => {
        if (data.status === 'SUCCESS') {
          loginService.login(formValues.email, formValues.password).then(({ data }) => {
            hideCircularProgress();
            if (data.status === 'SUCCESS' && data.data.num_rows > 0) {
              setLoginUser(data.data.data);
              navigate('/');
            }
          });
        } else {
          hideCircularProgress();
        }
      });
    } catch (error) {
      console.log(error);
      hideCircularProgress();
      if (error.code === "CodeMismatchException") {
        setFormErrors({
          email: "Please check your email again",
          code: error.message
        });
      } else if (error.code === "InvalidPasswordException") {
        setFormErrors({
          password: error.message,
        });
      } else if (error.code === "LimitExceededException") {
        setFormErrors({
          email: error.message,
        });
      }
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <VpnKeyOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className="mb-3">
            Password Reset
          </Typography>
          <div className={classes.form}>
            <Typography component="h5" className="mt-4" >
              Please input email you used for register
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              value={formValues.email}
              onChange={e => handleChange('email', e.target.value)}
              error={notNull(formErrors?.email)}
              helperText={formErrors?.email}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="my-2 py-3"
              onClick={handleSend}
            >
              {status === 0 ? 'Send Verification Code' : 'Resend'}
            </Button>

            {status > 0 && (
              <div className="mt-4">
                <Typography component="h5" className="mt-4" >
                  Please input verify code and new password
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Reset Code"
                  value={formValues.code}
                  onChange={e => handleChange('code', e.target.value)}
                  error={notNull(formErrors?.code)}
                  helperText={formErrors?.code}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="New Password"
                  type="password"
                  autoComplete="new-password"
                  value={formValues.password}
                  onChange={e => handleChange('password', e.target.value)}
                  error={notNull(formErrors?.password)}
                  helperText={formErrors?.password}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  autoComplete="new-password"
                  value={formValues.cpassword}
                  onChange={e => handleChange('cpassword', e.target.value)}
                  error={notNull(formErrors?.cpassword)}
                  helperText={formErrors?.cpassword}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="my-2 py-3"
                  onClick={handleReset}
                >
                  Reset Password
                </Button>
              </div>
            )}
            
           
            
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
