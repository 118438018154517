import React from 'react';
import MainLayout from 'layouts/mainLayout';
import PasswordPage from 'components/Pages/password';
import CircularProgressOverlay from 'components/CircularProgressOverlay';

const PasswordTemplate = () => (
  <MainLayout>
    <CircularProgressOverlay>
      <PasswordPage />
    </CircularProgressOverlay>
  </MainLayout>
);

export default PasswordTemplate;
